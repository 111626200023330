<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách Level</h6>
                  <router-link :to="'/wallet-run/level/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                    </b-button>
                  </router-link>
                </div>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(is_last_level)="data">
                  <span
                    v-if="data.item.is_last_level == 1"
                    class="btn btn-warning"
                  >
                    Cuối
                  </span>
                  <span v-else class="btn btn-default">Thường</span>
                </template>
                <template #cell(level_has_mission)="data">
                  <span
                    >Có {{ data.item.level_has_mission.length }} Nhiệm vụ</span
                  >
                </template>

                <template #cell(action)="data">
                  <router-link :to="'/wallet-run/level/view/' + data.item.id">
                    <b-button pill variant="success" size="sm">Xem </b-button>
                  </router-link>
                  <router-link :to="'/wallet-run/level/edit/' + data.item.id">
                    <b-button pill variant="warning" size="sm">Sửa </b-button>
                  </router-link>

                  <b-button
                    @click="destroy(data.item.id)"
                    pill
                    variant="outline-danger"
                    size="sm"
                    >Xóa
                  </b-button>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "List.vue",
  mixins: [Common],
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Tên LV",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "max_step_in_day",
          label: "Số bước tối đa/ ngày",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "step_convert_to_boxes",
          label: "Số bước quy đổi",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "box_value",
          label: "Số hộp quy đổi",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "group_step_percent",
          label: "% số bước đóng góp nhóm ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "group_max_get_boxes",
          label: "Số hộp quà tối đa nhận trong nhóm",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "is_last_level",
          label: "Loại level",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "level_has_mission",
          label: "Nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Thao tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý Level", route: "/wallet-run/level" },
    ]);
    this.filter = this.$route.query;
    this.getList();
  },
  methods: {
    countMissionInLevel(item) {
      let mission = JSON.parse(item);
      return mission.length;
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        let params = this.filter;
        params.page = this.currentPage;

        let response = await WalletRunRepository.getListLevel(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.data) {
          this.items = body.data;
          //this.currentPage = body.current_page
          this.paginate.total = body.total;
          this.paginate.totalPage = body.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async destroy(id) {
      if (confirm("Bạn có chắc xóa Level")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await WalletRunRepository.deleteLevel(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList();
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
  },
  watch: {
    //$route: "getList",
    currentPage() {
      this.getList();
    },
  },
};
</script>

<style scoped></style>
